import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function AddProductionProcess() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [post3, setPost3] = React.useState(null);
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2 style={{ color: '#047BD5' }}>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isThirdPartyAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles === 'administrator' || jsonObject2.userRoles === 'thirdPartyAdministrator');

            const state = {
                partyName: "",
                productName: "",
                composition: "",
                packing: "",
                mrp: "",
                quantity: "",
                updatedBy: jsonObject2.name,
                bottleType: "",
                bottleColour: "",
                stripType: "",
                baseFoilType: "",
                capType: "",
                liquidColour: "",
                liquidFlavour: "",
                tabletColour: "",
                expiryRequired: -1,
                remarks: "",
                capsuleColour: "",
                category: "",
                nonRefundablePDC: 0,
                refundablePDC: 0,
                doseCapType: "",
                rate: -1,
                orderType: "",
                quantityForProduction: ""
            };

            const handleChangeComposition = event => {
                state.composition = event.value.composition;
            };

            const handleChangeParties = event => {
                state.partyName = event.value.companyName;
            };

            const handleChangeOrderType = event => {
                state.orderType = event.value;
            }

            const handleChangeCategory = event => {
                state.category = event.value;

                if (document.getElementById("categoryId") != null) {
                    document.getElementById("categoryId").remove();
                }

                var a = document.createElement("div");
                a.setAttribute("id", "categoryId");
                document.getElementById("categorySpecificConfId").appendChild(a);

                if (state.category === "TABLET") {

                    // Strip Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "stripTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Strip Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.stripType = this.value;
                    });
                    y.appendChild(z);


                    // Base Foil Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "baseFoilTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Base Foil Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.baseFoilType = this.value;
                    });
                    y.appendChild(z);


                    // Tablet Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "tabletColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Tablet Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.tabletColour = this.value;
                    });
                    y.appendChild(z);

                } else if (state.category === "CAPSULE") {

                    // Strip Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "stripTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Strip Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.stripType = this.value;
                    });
                    y.appendChild(z);


                    // Base Foil Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "baseFoilTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Base Foil Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.baseFoilType = this.value;
                    });
                    y.appendChild(z);


                    // Capsule Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "capsuleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Capsule Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.capsuleColour = this.value;
                    });
                    y.appendChild(z);

                } else if (state.category === "LIQUID") {

                    // Bottle Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.bottleType = this.value;
                    });
                    y.appendChild(z);

                    // Bottle Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.bottleColour = this.value;
                    });
                    y.appendChild(z);

                    // Liquid Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.liquidColour = this.value;
                    });
                    y.appendChild(z);


                    // Liquid Flavour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidFlavourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Flavour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.liquidFlavour = this.value;
                    });
                    y.appendChild(z);


                    // Cap Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "capTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Cap Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.capType = this.value;
                    });
                    y.appendChild(z);

                    // Dose Cap Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "doseCapTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "doseCapTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Dose Cap Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "doseCapTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.doseCapType = this.value;
                    });
                    y.appendChild(z);

                } else {

                    // Bottle Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.bottleType = this.value;
                    });
                    y.appendChild(z);
                }           
            };

            const ProductCategories = [
                { label: "Tablets", value: "TABLET" },
                { label: "Capsules", value: "CAPSULE" },
                { label: "Oral Liquids", value: "LIQUID" },
                { label: "Dry Syrup", value: "DRY_SYRUP" }
            ]

            const OrderTypes = [
                { label: "New", value: "New" },
                { label: "Repeat", value: "Repeat" }
            ]

            const onUpload = () => {

                if (isThirdPartyAdmin == true) {

                    if (state.category == "TABLET") {
                        state.bottleType = "";
                        state.bottleColour = "";
                        state.liquidColour = "";
                        state.liquidFlavour = "";
                        state.capType = "";
                        state.doseCapType = "";
                        state.capsuleColour = "";
                    } else if (state.category == "CAPSULE") {
                        state.bottleType = "";
                        state.bottleColour = "";
                        state.liquidColour = "";
                        state.liquidFlavour = "";
                        state.capType = "";
                        state.doseCapType = "";
                        state.tabletColour = "";
                    } else if (state.category == "LIQUID") {
                        state.baseFoilType = "";
                        state.stripType = "";
                        state.tabletColour = "";
                        state.capsuleColour = "";
                    } else {
                        state.baseFoilType = "";
                        state.stripType = "";
                        state.tabletColour = "";
                        state.capsuleColour = "";
                        state.bottleColour = "";
                        state.liquidColour = "";
                        state.liquidFlavour = "";
                        state.capType = "";
                        state.doseCapType = "";
                    }

                    // Create an object of formData
                    const formData = new FormData();

                    formData.append("partyName", state.partyName);
                    formData.append("productName", state.productName);
                    formData.append("composition", state.composition);
                    formData.append("packing", state.packing);
                    formData.append("mrp", state.mrp);
                    formData.append("quantity", state.quantity);
                    formData.append("updatedBy", state.updatedBy);
                    formData.append("bottleType", state.bottleType);
                    formData.append("bottleColour", state.bottleColour);
                    formData.append("stripType", state.stripType);
                    formData.append("baseFoilType", state.baseFoilType);
                    formData.append("capType", state.capType);
                    formData.append("liquidColour", state.liquidColour);
                    formData.append("liquidFlavour", state.liquidFlavour);
                    formData.append("tabletColour", state.tabletColour);
                    formData.append("expiryRequired", state.expiryRequired);
                    formData.append("remarks", state.remarks);
                    formData.append("capsuleColour", state.capsuleColour);
                    formData.append("category", state.category);
                    formData.append("nonRefundablePDC", state.nonRefundablePDC);
                    formData.append("refundablePDC", state.refundablePDC);
                    formData.append("doseCapType", state.doseCapType);
                    formData.append("rate", state.rate);
                    formData.append("orderType", state.orderType);
                    formData.append("quantityForProduction", state.quantityForProduction);

                    var button = document.getElementById("createPO");
                    button.disabled = true;
                    axios.post("https://cascamailsender.azurewebsites.net/createproductionorder", formData)
                        .then((response) => {
                            if (response.data === "ORDER_CREATED") {
                                alert("Order Created");
                                window.location.reload();
                            } else {
                                button.disabled = false;
                            }
                        });
                } else {
                    alert("You are not authorized to perform this operation");
                }
            };

            const Compositions = [];

            fetch('https://cascamailsender.azurewebsites.net/getallcompositions', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Compositions.push({ label: object.composition + ", " + object.category, value: object });
            })

            const Parties = [];

            fetch('https://cascaappservice.azurewebsites.net/all_users_read/', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost3(data));

            if (!post3) return null;

            var postModified3 = post3.replace(/'/g, '"');
            var jsonData3 = JSON.parse(JSON.stringify(postModified3));
            var jsonObject3 = JSON.parse(jsonData3);
            jsonObject3.forEach(object => {
                Parties.push({ label: object.companyName + ", " + object.city + " (" + object.username + ")" + " (UserId: " + object.id + ")", value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Create Production Order</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Party Name</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeParties.bind(this)} options={Parties} id="partyName" name="partyName">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.productName = event.target.value; }} type="text" id="productName" name="productName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeComposition.bind(this)} options={Compositions} id="composition" name="composition">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.packing = event.target.value; }} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>MRP</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.mrp = event.target.value; }} type="number" step="0.01" id="mrp" name="mrp"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.quantity = event.target.value; }} type="number" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Rate</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.rate = event.target.value; }} type="number" step="0.01" id="rate" name="rate"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Order Type</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeOrderType.bind(this)} options={OrderTypes} id="orderType" name="orderType">
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Category</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeCategory.bind(this)} options={ProductCategories} id="category" name="category">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="categorySpecificConfId">

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Expiry Required</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.expiryRequired = event.target.value; }} type="number" id="expiryRequired" name="expiryRequired"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Non Refundable PDC</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.nonRefundablePDC = event.target.value; }} type="number" id="nonRefundablePDC" name="nonRefundablePDC"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Refundable PDC</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.refundablePDC = event.target.value; }} type="number" id="refundablePDC" name="refundablePDC"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Production Quantity (no of tablets/capsules or litres for liquid)</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.quantityForProduction = event.target.value; }} type="number" id="quantityForProduction" name="quantityForProduction"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Remarks</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.remarks = event.target.value; }} type="text" id="remarks" name="remarks"></input>
                        </div>

                    </div>

                    <button class="button" id="createPO" onClick={onUpload}>CREATE</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}