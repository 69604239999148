import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function CreateProductionBatch() {

    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles === 'administrator'
                || jsonObject2.userRoles === 'thirdPartyAdministrator');

            const state = {
                composition: "",
                tabletShape: "",
                tabletColour: "",
                capsuleColour: "",
                liquidColour: "",
                liquidFlavour: "",
                category: "",
                remarks: "",
                updatedBy: jsonObject2.name
            };

            const handleChangeCategory = event => {
                state.category = event.value;

                var url = "https://cascamailsender.azurewebsites.net/getproductionpendingorderscomposition?category=" + state.category;
                axios.get(url)
                    .then((response) => {

                        compositions.splice(0, compositions.length);

                        response.data.forEach((pendingCompositions) => {
                            var label = pendingCompositions.composition + ", Created At: " + pendingCompositions.updatedAt;

                            if (!compositions.some(e => e.label === label)) {
                                compositions.push({ label: label, value: pendingCompositions });
                            }
                        });
                    });

                if (document.getElementById("categoryId") != null) {
                    document.getElementById("categoryId").remove();
                }

                var a = document.createElement("div");
                a.setAttribute("id", "categoryId");
                document.getElementById("categorySpecificConfId").appendChild(a);

                if (state.category === "TABLET") {

                    // Tablet Shape
                    var x = document.createElement("div");
                    x.setAttribute("id", "tabletShapeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletShapeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Tablet Shape";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletShapeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.tabletShape = this.value;
                    });
                    y.appendChild(z);

                    // Tablet Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "tabletColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Tablet Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.tabletColour = this.value;
                    });
                    y.appendChild(z);

                } else if (state.category === "CAPSULE") {
                    
                    // Capsule Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "capsuleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Capsule Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.capsuleColour = this.value;
                    });
                    y.appendChild(z);

                } else if (state.category === "LIQUID") {
                    
                    // Liquid Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.liquidColour = this.value;
                    });
                    y.appendChild(z);


                    // Liquid Flavour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidFlavourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Flavour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.liquidFlavour = this.value;
                    });
                    y.appendChild(z);

                }     
            }

            const handleChangeComposition = event => {
                state.composition = event.value.composition;

                if (document.getElementById("myTableBody") != null) {
                    document.getElementById("myTableBody").remove();
                }

                var y = document.createElement("tbody");
                y.setAttribute("id", "myTableBody");
                document.getElementById("myTable").appendChild(y);

                const formData = new FormData();
                formData.append("composition", state.composition)
                formData.append("category", state.category)
                axios.post("https://cascamailsender.azurewebsites.net/getproductionpendingordersforcomposition", formData)
                    .then((response) => {
                        response.data.forEach((productionProcess) => {

                            var y = document.createElement("tr");
                            y.setAttribute("id", productionProcess.id);
                            document.getElementById("myTableBody").appendChild(y);


                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createElement("input");
                            t.setAttribute("type", "checkbox");
                            t.setAttribute("id", productionProcess.id);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.partyName);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.productName);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.packing);
                            z.appendChild(t);
                            y.appendChild(z);


                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.mrp);
                            z.appendChild(t);
                            y.appendChild(z);


                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.quantity);
                            z.appendChild(t);
                            y.appendChild(z);


                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.quantityForProduction);
                            z.appendChild(t);
                            y.appendChild(z);

                            var remarks = "";
                            if (state.category === "TABLET") {
                                remarks = "Strip Type: " + productionProcess.stripType + ", Base Foil Type: "
                                    + productionProcess.baseFoilType + ", Tablet Colour: " + productionProcess.tabletColour
                                    + ", Expiry Required: " + productionProcess.expiryRequired + ", Remarks: "
                                    + productionProcess.remarks;
                            } else if (state.category === "CAPSULE") {
                                remarks = "Strip Type: " + productionProcess.stripType + ", Base Foil Type: "
                                    + productionProcess.baseFoilType + ", Capsule Colour: " + productionProcess.capsuleColour
                                    + ", Expiry Required: " + productionProcess.expiryRequired + ", Remarks: "
                                    + productionProcess.remarks;
                            } else if (state.category === "LIQUID") {
                                remarks = "Liquid Colour: " + productionProcess.liquidColour + ", Liquid Flavour: " +
                                    productionProcess.liquidFlavour + ", Expiry Required: " + productionProcess.expiryRequired
                                    + ", Remarks: " + productionProcess.remarks;
                            } else {
                                remarks = "Expiry Required: " + productionProcess.expiryRequired
                                    + ", Remarks: " + productionProcess.remarks;
                            }
                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(remarks);
                            z.appendChild(t);
                            y.appendChild(z);

                        });
                    });
            };

            const modifyAction = event => {

                if (isAdmin == true) {

                    const orderIdList = [];

                    var grid = document.getElementById("myTable");
                    //Reference the CheckBoxes in Table.
                    var checkBoxes = grid.getElementsByTagName("input");
                    //Loop through the CheckBoxes.
                    for (var i = 0; i < checkBoxes.length; i++) {
                        if (checkBoxes[i].checked) {
                            orderIdList.push(checkBoxes[i].id);
                        }
                    }

                    if (state.category == "TABLET") {
                        state.liquidColour = "";
                        state.liquidFlavour = "";
                        state.capsuleColour = "";
                    } else if (state.category == "CAPSULE") {
                        state.liquidColour = "";
                        state.liquidFlavour = "";
                        state.tabletColour = "";
                        state.tabletShape = "";
                    } else if (state.newCategory == "LIQUID") {
                        state.tabletColour = "";
                        state.tabletShape = "";
                        state.capsuleColour = "";
                    } else {
                        state.liquidColour = "";
                        state.liquidFlavour = "";
                        state.capsuleColour = "";
                        state.tabletColour = "";
                        state.tabletShape = "";
                    }

                    const formData = new FormData();
                    formData.append("orderIdList", orderIdList)
                    formData.append("composition", state.composition)
                    formData.append("tabletShape", state.tabletShape)
                    formData.append("tabletColour", state.tabletColour)
                    formData.append("capsuleColour", state.capsuleColour)
                    formData.append("liquidColour", state.liquidColour)
                    formData.append("liquidFlavour", state.liquidFlavour)
                    formData.append("category", state.category)
                    formData.append("remarks", state.remarks)
                    formData.append("updatedBy", state.updatedBy);

                    var button = document.getElementById("createBatch");
                    button.disabled = true;
                    axios.post("https://cascamailsender.azurewebsites.net/createproductionbatch", formData)
                        .then((response) => {
                            if (response.data === "PRODUCTION_BATCH_CREATED") {
                                alert("Production Batch Created");
                                window.location.reload();
                            } else {
                                button.disabled = false;
                            }
                        });
                } else {
                    alert("You are not authorized to perform this operation");
                }
            }

            const ProductCategories = [
                { label: "Tablets", value: "TABLET" },
                { label: "Capsules", value: "CAPSULE" },
                { label: "Oral Liquids", value: "LIQUID" },
                { label: "Dry Syrup", value: "DRY_SYRUP" }
            ]

            var compositions = [];

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Create Production Batch</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Category</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeCategory.bind(this)} options={ProductCategories} id="category" name="category">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeComposition.bind(this)} options={compositions} id="composition" name="composition">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Production Orders</h4>
                        </div>

                        <div class="flex-child green">
                            <table class="border_class" id="myTable">
                                <tr>
                                    <th class="border_class">Select</th>
                                    <th class="border_class">Party Name</th>
                                    <th class="border_class">Product Name</th>
                                    <th class="border_class">Packing</th>
                                    <th class="border_class">MRP</th>
                                    <th class="border_class">Quantity</th>
                                    <th class="border_class">Production Quantity</th>
                                    <th class="border_class">Details</th>
                                </tr>
                            </table>
                        </div>

                    </div>

                    <div id="categorySpecificConfId">

                    </div>                                                                                         

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Remarks</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.remarks = event.target.value; }} type="text" id="remarks" name="remarks"></input>
                        </div>

                    </div>

                    <button class="button" id="createBatch" onClick={modifyAction}>CREATE</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}