import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function ProductModification() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            const state = {

                // Initially, no file is selected
                productId: null,
                division: null,
                brandName: null,
                composition: null,
                packing: null,
                mrp: null,
                gst: null,
                dlRequired: null,
                imageUrl: null,
                visualPageUrl: null,
                image: null,
                thumbnailImage: null,
                visualImage: null,
                visual: null
            };

            const onImageChange = event => {
                state.image = event.target.files[0];
            };

            const onThumbnailImageChange = event => {
                state.thumbnailImage = event.target.files[0];
            };

            const onVisualImageChange = event => {
                state.visualImage = event.target.files[0];
            };

            const handleChange = event => {
                // Update the state
                var inputDivision = document.getElementById("division");
                var inputBrandname = document.getElementById("productName");
                var inputcomp = document.getElementById("composition");
                var inputpack = document.getElementById("packing");
                var inputmrp = document.getElementById("mrp");
                var inputgst = document.getElementById("gst");
                var inputdl = document.getElementById("dl");
                var inputvisual = document.getElementById("visual");
                var inputimageUrl = document.getElementById("imageUrl");
                var inputvisualPageUrl = document.getElementById("visualPageUrl");

                state.productId = event.value.id;
                state.division = event.value.division;
                state.brandName = event.value.brandName;
                state.composition = event.value.composition;
                state.packing = event.value.packing;
                state.mrp = event.value.mrp;
                state.gst = event.value.gst;
                state.dlRequired = event.value.dlRequired;
                state.visual = event.value.visualPageRef;
                state.imageUrl = event.value.imageUrl
                state.visualPageUrl = event.value.visualPageUrl;

                inputDivision.value = event.value.division;
                inputBrandname.value = event.value.brandName;
                inputcomp.value = event.value.composition;
                inputpack.value = event.value.packing;
                inputmrp.value = event.value.mrp;
                inputgst.value = event.value.gst;
                inputdl.value = event.value.dlRequired;
                inputvisual.value = event.value.visualPageRef;
                inputimageUrl.value = event.value.imageUrl;
                inputvisualPageUrl.value = event.value.visualPageRefUrl;
            };

            const onUpload = () => {

                if (jsonObject2.userRoles != null && jsonObject2.userRoles == 'administrator') {

                    // Create an object of formData
                    const formData = new FormData();

                    // Update the formData object

                    if (state.image != null) {
                        formData.append(
                            "multipartImage",
                            state.image,
                            state.image.name
                        );
                    }

                    if (state.thumbnailImage != null) {
                        formData.append(
                            "multipartThumbnailImage",
                            state.thumbnailImage,
                            state.thumbnailImage.name
                        );
                    }

                    if (state.visualImage != null) {
                        formData.append(
                            "multipartVisualImage",
                            state.visualImage,
                            state.visualImage.name
                        );
                    }

                    formData.append("productId", state.productId);
                    formData.append("division", state.division);
                    formData.append("brandName", state.brandName);
                    formData.append("composition", state.composition);
                    formData.append("packing", state.packing);
                    formData.append("mrp", state.mrp);
                    formData.append("gst", state.gst);
                    formData.append("dlRequired", state.dlRequired);
                    formData.append("visual", state.visual);

                    axios.post("https://cascamailsender.azurewebsites.net/productdetailsmodify", formData)
                        .then((response) => {
                            alert(response.data)
                        });
                } else {
                    alert("You are not authorized to perform this operation");
                }       
            };

            const Products = [];

            fetch('https://cascaappservice.azurewebsites.net/product_details_read_v4/?user_id=11', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Products.push({ label: object.brandName + ", " + object.category + ", " + object.packing + ", MRP: " + object.mrp + " (" + object.id + ")", value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Product Modification</h2>
                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={Products} id="partyname" name="partyname">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Division</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.division = event.target.value; }} type="text" id="division" name="division"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.brandName = event.target.value; }} type="text" id="productName" name="productName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.composition = event.target.value; }} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.packing = event.target.value; }} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>MRP</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.mrp = event.target.value; }} type="text" id="mrp" name="mrp"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>GST(in %)</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.gst = event.target.value; }} type="text" id="gst" name="gst"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>DL Required</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.dlRequired = event.target.value; }} type="text" id="dl" name="dl"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Visual Page</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.visual = event.target.value; }} type="text" id="visual" name="visual"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Image URL</h4>
                        </div>

                        <div class="flex-child green">
                            <input type="text" id="imageUrl" name="imageUrl"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Visual Page URL</h4>
                        </div>

                        <div class="flex-child green">
                            <input type="text" id="visualPageUrl" name="visualPageUrl"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Image</h4>
                        </div>

                        <div class="flex-child green">
                            <input type="file" id="image" name="image" onChange={onImageChange}></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Thumbnail Image</h4>
                        </div>

                        <div class="flex-child green">
                            <input type="file" id="thumbnailImage" name="thumbnailImage" onChange={onThumbnailImageChange}></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Visual Image</h4>
                        </div>

                        <div class="flex-child green">
                            <input type="file" id="visualImage" name="visualImage" onChange={onVisualImageChange}></input>
                        </div>

                    </div>

                    <button class="button" onClick={onUpload}>MODIFY</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}