import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function AddProductionComposition() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2 style={{ color: '#047BD5' }}>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            const state = {
                composition: "",
                category: "",    
                updatedBy: jsonObject2.name
            };

            const handleChangeCategory = event => {
                state.category = event.value;               
            };

            const ProductCategories = [
                { label: "Tablets", value: "TABLET" },
                { label: "Capsules", value: "CAPSULE" },
                { label: "Oral Liquids", value: "LIQUID" },
                { label: "Dry Syrup", value: "DRY_SYRUP" }
            ]

            const onUpload = () => {

                if (jsonObject2.userRoles != null && jsonObject2.userRoles == 'administrator') {

                    const formData = new FormData();
                    formData.append("composition", state.composition);
                    formData.append("category", state.category);

                    var button = document.getElementById("addButton");
                    button.disabled = true;
                    axios.post("https://cascamailsender.azurewebsites.net/addcomposition", formData)
                        .then((response) => {
                            alert(response.data)
                            window.location.reload();
                        }).catch(function (error) {
                            alert("ERROR!");
                            button.disabled = false;
                        });
                } else {
                    alert("You are not authorized to perform this operation");
                }
            };

            const Compositions = [];

            fetch('https://cascamailsender.azurewebsites.net/getallcompositions', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Compositions.push({ label: object.composition + ", " + object.category, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Add Unique Production Composition</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>                  

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Existing Compositions</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select options={Compositions} id="existingCompositions" name="existingCompositions">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Category</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeCategory.bind(this)} options={ProductCategories} id="category" name="category">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.composition = event.target.value; }} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>                   

                    <button class="button" id="addButton" onClick={onUpload}>ADD</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}