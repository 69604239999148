import React from 'react';
import '../App.css';
/*
                <a className="box" style={{ color: 'white' }} href="home">Home</a>
                <a className="box" style={{ color: 'white' }} href="invoiceupload">Upload Invoice</a>
                <a className="box" style={{ color: 'white' }} href="attendance">Attendance</a>
                <a className="box" style={{ color: 'white' }} href="viewgstinvoices">View GST Invoices</a>
                <a className="box" style={{ color: 'white' }} href="logout">Logout</a>*/
const SideNav = (props) => {
        return (
            <div className="sidenav">
                <a className="box" style={{ color: 'white' }} href="">Home</a>
                <a className="box" style={{ color: 'white' }} href="createorder">Create PCD Order</a>
                <a className="box" style={{ color: 'white' }} href="useradd">Add App User</a>
                <a className="box" style={{ color: 'white' }} href="fetchUserDetails">Get App User Details</a>
                <a className="box" style={{ color: 'white' }} href="invoiceupload">Upload Invoice</a>
                <a className="box" style={{ color: 'white' }} href="trackingdetailsupload">Upload Tracking Details</a>
                <a className="box" style={{ color: 'white' }} href="productmodification">Modify PCD Product</a>
                <a className="box" style={{ color: 'white' }} href="productadd">Add PCD Product</a>
                <a className="box" style={{ color: 'white' }} href="sendnotification">Send App Notification</a>
                <a className="box" style={{ color: 'white' }} href="attendance">Attendance Details</a>
                <a className="box" style={{ color: 'white' }} href="addproductioncomposition">Add Production Composition</a>
                <a className="box" style={{ color: 'white' }} href="addproductionprocess">Add Production Product</a>
                <a className="box" style={{ color: 'white' }} href="modifyproductionprocess">Modify Production Product</a>
                <a className="box" style={{ color: 'white' }} href="updateproductionprocess">Update Production Product</a>
                <a className="box" style={{ color: 'white' }} href="createproductionbatch">Create Production Batch</a>
                <a className="box" style={{ color: 'white' }} href="mergeproductionbatches">Merge Production Batches</a>
                <a className="box" style={{ color: 'white' }} href="allotproductionbatch">Allot Production Batch</a>
                <a className="box" style={{ color: 'white' }} href="updatebatchstage">Update Batch Stage</a>
                <a className="box" style={{ color: 'white' }} href="viewproductorderdetails">View Product Order Details</a>
                <a className="box" style={{ color: 'white' }} href="pendingproductionorders">Pending Production Orders</a>
                <a className="box" style={{ color: 'white' }} href="viewproductionstagesfororder">View Production Stages</a>
                <a className="box" style={{ color: 'white' }} href="thirdpartyratecalculator">Third Party Rate Calculator</a>
                <a className="box" style={{ color: 'white' }} href="addcostsheet">Add Cost Sheet</a>
                <a className="box" style={{ color: 'white' }} href="addrawmaterial">Add Raw Material</a>
                <a className="box" style={{ color: 'white' }} href="modifyrawmaterial">Modify Raw Material</a>
                <a className="box" style={{ color: 'white' }} href="changepassword">Change Password</a>
                <a className="box" style={{ color: 'white' }} href="logout">Logout</a>
            </div >
         );
    };
export default SideNav;