import './App.css';
import React from "react";
import axios from "axios";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "./background.jpg";
import cascaLogo from "./casca_logo.png";
import { TrackingDetailsUpload } from './components/TrackingDetailsUpload';
import { InvoiceUpload } from './components/InvoiceUpload';
import { ProductModification } from './components/ModifyProduct';
import { AddProduct } from './components/AddProduct';
import { AddUser } from './components/AddUser';
import { SendNotification } from './components/SendNotification';
import { CreateOrder } from './components/CreateOrder';
import { UserDetails } from './components/UserDetails';
import { FetchInvoices } from './components/FetchInvoices';
import { Logout } from './components/Logout';

import SideNav from './components/SideNav';

import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import { Button } from 'bootstrap';
import { Home } from './components/Home';
import { Sections } from './components/Sections'
import { IndividualSection } from './components/IndividualSection'
import { Attendance } from './components/Attendance'
import { Home2 } from './components/Home2';
import { ViewProductionStagesForOrder } from './components/ViewProductionStagesForOrder';
import { AddProductionProcess } from './components/AddProductionProcess';
import { ModifyProductionProcess } from './components/ModifyProductionProcess';
import { UpdateProductionProcess } from './components/UpdateProductionProcess';
import { CreateProductionBatch } from './components/CreateProductionBatch';
import { MergeProductionBatches } from './components/MergeProductionBatches';
import { AllotProductionBatch } from './components/AllotProductionBatch';
import { UpdateBatchStage } from './components/UpdateBatchStage';
import { AddProductionComposition } from './components/AddProductionComposition';
import { PendingProductionOrders } from './components/PendingProductionOrders';
import { ThirdPartyRateCalculator } from './components/ThirdPartyRateCalculator';
import { AddCostSheet } from './components/AddCostSheet';
import { AddRawMaterial } from './components/AddRawMaterial';
import { ModifyRawMaterial } from './components/ModifyRawMaterial';
import { DeleteAccount } from './components/DeleteAccount';
import { ChangePassword } from './components/ChangePassword';
import { ViewProductOrderDetails } from './components/ViewProductOrderDetails';

export default function AuthDashboard() {

    const history = useHistory();

    const state = {

        // Initially, no file is selected
        username: "",
        password: "",
    };

    const onUpload = () => {

        const formData = new FormData();

        formData.append("username", state.username);
        formData.append("password", state.password);

        axios.post("https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/", formData, { mode: 'cors' })
            .then((response) => {
                if (response.data != null && response.data != "") {
                    localStorage.setItem('cascadashboard3', 'true');
                    localStorage.setItem('cascadashboard3-username', state.username);
                    localStorage.setItem('cascadashboard3-password', state.password);
                    localStorage.setItem('cascadashboard3-name', state.name);
                    localStorage.setItem('cascadashboard3-id', response.data.id);
                    alert("Welcome " + state.username + "!");
                    history.push('/home');
                } else {
                    alert("Login Failed. Please check your Username or Password");
                }
            });
    };

    const [post, setPost] = React.useState(null);
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        /*axios.post("https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/", formData, { mode: 'cors' })
            .then((response) => {
                setPost(response.data);
                setLoading(false);
            }).catch();*/

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost(data);
                setLoading(false);
            });

        if (!post) return null;

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post != null && post != "") {

            return (
                <div>

                    <div class="split_bottom">

                        <Router>
                            <Switch>
                                <Route path="/home"> <Home2 /></Route>
                                <Route path="/invoiceupload"><InvoiceUpload /></Route>
                                <Route path='/trackingdetailsupload'><TrackingDetailsUpload /></Route>
                                <Route path='/productmodification'><ProductModification /></Route>
                                <Route path='/productadd'><AddProduct /></Route>
                                <Route path='/useradd'><AddUser /></Route>
                                <Route path='/sendnotification'><SendNotification /></Route>
                                <Route path='/createorder'><CreateOrder /></Route>
                                <Route path='/fetchuserdetails'><UserDetails /></Route>
                                <Route path='/attendance'><Attendance /></Route>
                                <Route path='/addproductionprocess'><AddProductionProcess /></Route>
                                <Route path='/modifyproductionprocess'><ModifyProductionProcess /></Route>
                                <Route path='/updateproductionprocess'><UpdateProductionProcess /></Route>
                                <Route path='/viewproductionstagesfororder'><ViewProductionStagesForOrder /></Route>
                                <Route path='/pendingproductionorders'><PendingProductionOrders /></Route>
                                <Route path='/thirdpartyratecalculator'><ThirdPartyRateCalculator /></Route>
                                <Route path='/addcostsheet'><AddCostSheet /></Route>
                                <Route path='/addrawmaterial'><AddRawMaterial /></Route>
                                <Route path='/modifyrawmaterial'><ModifyRawMaterial /></Route>
                                <Route path='/deleteaccount'><DeleteAccount /></Route>
                                <Route path='/logout'><Logout /></Route>
                                <Route path='/createproductionbatch'><CreateProductionBatch /></Route>
                                <Route path='/mergeproductionbatches'><MergeProductionBatches /></Route>
                                <Route path='/allotproductionbatch'><AllotProductionBatch /></Route>
                                <Route path='/updatebatchstage'><UpdateBatchStage /></Route>
                                <Route path='/addproductioncomposition'><AddProductionComposition /></Route>
                                <Route path='/changepassword'><ChangePassword /></Route>
                                <Route path='/viewproductorderdetails'><ViewProductOrderDetails /></Route>

                                <Redirect to="/home"/>
                            </Switch>
                        </Router>

                        <div>
                            <SideNav name="sideNav" >
                            </SideNav>
                        </div>

                    </div>



                </div>
            );
        } else {
            return (

                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="Auth">

                    <Router>
                        <Switch>
                            <Route path="/home"> <Home2 /></Route>
                            <Route path="/invoiceupload"><InvoiceUpload /></Route>
                            <Route path='/trackingdetailsupload'><TrackingDetailsUpload /></Route>
                            <Route path='/productmodification'><ProductModification /></Route>
                            <Route path='/productadd'><AddProduct /></Route>
                            <Route path='/useradd'><AddUser /></Route>
                            <Route path='/sendnotification'><SendNotification /></Route>
                            <Route path='/createorder'><CreateOrder /></Route>
                            <Route path='/fetchuserdetails'><UserDetails /></Route>
                            <Route path='/attendance'><Attendance /></Route>
                            <Route path='/addproductionprocess'><AddProductionProcess /></Route>
                            <Route path='/modifyproductionprocess'><ModifyProductionProcess /></Route>
                            <Route path='/updateproductionprocess'><UpdateProductionProcess /></Route>
                            <Route path='/viewproductionstagesfororder'><ViewProductionStagesForOrder /></Route>
                            <Route path='/pendingproductionorders'><PendingProductionOrders /></Route>
                            <Route path='/thirdpartyratecalculator'><ThirdPartyRateCalculator /></Route>
                            <Route path='/addcostsheet'><AddCostSheet /></Route>
                            <Route path='/addrawmaterial'><AddRawMaterial /></Route>
                            <Route path='/modifyrawmaterial'><ModifyRawMaterial /></Route>
                            <Route path='/deleteaccount'><DeleteAccount /></Route>
                            <Route path='/logout'><Logout /></Route>
                            <Route path='/createproductionbatch'><CreateProductionBatch /></Route>
                            <Route path='/mergeproductionbatches'><MergeProductionBatches /></Route>
                            <Route path='/allotproductionbatch'><AllotProductionBatch /></Route>
                            <Route path='/updatebatchstage'><UpdateBatchStage /></Route>
                            <Route path='/addproductioncomposition'><AddProductionComposition /></Route>
                            <Route path='/changepassword'><ChangePassword /></Route>
                            <Route path='/viewproductorderdetails'><ViewProductOrderDetails /></Route>

                        </Switch>
                    </Router>

                    <h2>Welcome to CASCA Dashboard Login Page</h2>

                    <div style={{ marginTop: "30vh" }} class="flex-container">

                        <div class="auth-child magenta">
                            <h4>Username</h4>
                        </div>

                        <div class="auth-child green">
                            <input onChange={event => { state.username = event.target.value; }} type="text" id="username" name="username"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="auth-child magenta">
                            <h4>Password</h4>
                        </div>

                        <div class="auth-child green">
                            <input onChange={event => { state.password = event.target.value; }} type="password" id="password" name="password"></input>
                        </div>

                    </div>

                    <div>
                        <button onClick={onUpload} className="button">LOGIN</button>
                    </div>

                </div>
            );
        }
        
    } else {

        return (

            <div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="Auth">

                <Router>
                    <Switch>
                        <Route path="/home"> <Home2 /></Route>
                        <Route path="/invoiceupload"><InvoiceUpload /></Route>
                        <Route path='/trackingdetailsupload'><TrackingDetailsUpload /></Route>
                        <Route path='/productmodification'><ProductModification /></Route>
                        <Route path='/productadd'><AddProduct /></Route>
                        <Route path='/useradd'><AddUser /></Route>
                        <Route path='/sendnotification'><SendNotification /></Route>
                        <Route path='/createorder'><CreateOrder /></Route>
                        <Route path='/fetchuserdetails'><UserDetails /></Route>
                        <Route path='/attendance'><Attendance /></Route>
                        <Route path='/addproductionprocess'><AddProductionProcess /></Route>
                        <Route path='/modifyproductionprocess'><ModifyProductionProcess /></Route>
                        <Route path='/updateproductionprocess'><UpdateProductionProcess /></Route>
                        <Route path='/viewproductionstagesfororder'><ViewProductionStagesForOrder /></Route>
                        <Route path='/pendingproductionorders'><PendingProductionOrders /></Route>
                        <Route path='/thirdpartyratecalculator'><ThirdPartyRateCalculator /></Route>
                        <Route path='/addcostsheet'><AddCostSheet /></Route>
                        <Route path='/addrawmaterial'><AddRawMaterial /></Route>
                        <Route path='/modifyrawmaterial'><ModifyRawMaterial /></Route>
                        <Route path='/deleteaccount'><DeleteAccount /></Route>
                        <Route path='/logout'><Logout /></Route>
                        <Route path='/createproductionbatch'><CreateProductionBatch /></Route>
                        <Route path='/mergeproductionbatches'><MergeProductionBatches /></Route>
                        <Route path='/allotproductionbatch'><AllotProductionBatch /></Route>
                        <Route path='/updatebatchstage'><UpdateBatchStage /></Route>
                        <Route path='/addproductioncomposition'><AddProductionComposition /></Route>
                        <Route path='/changepassword'><ChangePassword /></Route>
                        <Route path='/viewproductorderdetails'><ViewProductOrderDetails /></Route>

                    </Switch>
                </Router>

                <h2>Welcome to CASCA Dashboard Login Page</h2>

                <div style={{ marginTop: "30vh" }} class="flex-container">

                    <div class="auth-child magenta">
                        <h4>Username</h4>
                    </div>

                    <div class="auth-child green">
                        <input onChange={event => { state.username = event.target.value; }} type="text" id="username" name="username"></input>
                    </div>

                </div>

                <div class="flex-container">

                    <div class="auth-child magenta">
                        <h4>Password</h4>
                    </div>

                    <div class="auth-child green">
                        <input onChange={event => { state.password = event.target.value; }} type="password" id="password" name="password"></input>
                    </div>

                </div>

                <div>
                    <button onClick={onUpload} className="button">LOGIN</button>
                </div>

            </div>
        );
    }
}