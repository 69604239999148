import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function ChangePassword() {

    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        const password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            const state = {
                username: jsonObject2.username,
                existingPassword: "",
                newPassword: "",
                verifyPassword: "",
                dashboardUserId: jsonObject2.id
            };

            const modifyAction = event => {

                if (state.newPassword != state.verifyPassword) {
                    alert("New Password and Re-enter New Password are different");
                    return;
                }

                if (state.existingPassword != password) {
                    alert("You have entered wrong Existing Password");
                    return;
                }

                const formData = new FormData();
                formData.append("id", state.dashboardUserId)
                formData.append("password", state.newPassword);

                axios.post("https://cascamailsender.azurewebsites.net/changedashboarduserpassword", formData)
                    .then((response) => {
                        if (response.data === "CHANGED") {
                            alert("Password Changed");
                            localStorage.setItem('cascadashboard3-password', state.newPassword);
                            window.location.reload();
                        }
                    });
            }

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Merge Production Batches</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Username</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={state.username} type="text" id="username" name="username"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Existing Password</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.existingPassword = event.target.value; }} type="password" id="existingPassword" name="existingPassword"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>New Password</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newPassword = event.target.value; }} type="password" id="newPassword" name="newPassword"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Re-enter New Password</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.verifyPassword = event.target.value; }} type="password" id="verifyPassword" name="verifyPassword"></input>
                        </div>

                    </div>

                    <button class="button" onClick={modifyAction}>CHANGE</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}