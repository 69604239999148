import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function UpdateProductionProcess() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles === 'administrator' 
                || jsonObject2.userRoles === 'purchaseAdministrator' || jsonObject2.userRoles === 'productionAdministrator'
                || jsonObject2.userRoles === 'designAdministrator' || jsonObject2.userRoles === 'packingMaterialAdministrator'
                || jsonObject2.userRoles === 'productionMember');

            const state = {
                orderId: "",
                stage: "",
                primaryOperator: "",
                helpers: "",
                machineNo: "",
                shiftNo: "",
                reason: "",
                yield: "",
                updatedBy: jsonObject2.name,
                quantity: "",
                packingVariant: "",
                packingType: "",
                batchNo: ""
            };

            const handleChange = event => {
                state.orderId = event.value.id;
            };

            const handleChangeStage = event => {
                state.stage = event.value;

                if (document.getElementById("oldStockName") != null) {
                    document.getElementById("oldStockName").remove();
                    document.getElementById("oldStockValue").remove();
                }

                if (state.stage == "EXISTING_OUTER_BOX_STOCK_CHECK" || state.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || state.stage == "EXISTING_FOIL_STOCK_CHECK" || state.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || state.stage == "EXISTING_LABEL_STOCK_CHECK"
                    || state.stage == "OUTER_BOX_REC" || state.stage == "INNER_BOX_REC" || state.stage == "FOIL_REC" || state.stage == "BASE_FOIL_REC" || state.stage == "LABEL_REC") {

                    var y = document.createElement("div");
                    y.setAttribute("id", "oldStockName");
                    y.setAttribute("class", "flex-child magenta");
                    document.getElementById("packingMaterialStockId").appendChild(y);

                    state.packingType = "EXISTING";
                    if (state.stage == "OUTER_BOX_REC" || state.stage == "INNER_BOX_REC" || state.stage == "FOIL_REC" || state.stage == "BASE_FOIL_REC" || state.stage == "LABEL_REC") {
                        state.packingType = "NEW";
                    }


                    var stockVariant = "Outer Box Quantity";
                    state.packingVariant = "OUTER_BOX";
                    if (state.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || state.stage == "INNER_BOX_REC") {
                        stockVariant = "Inner Box Quantity";
                        state.packingVariant = "INNER_BOX";
                    } else if (state.stage == "EXISTING_FOIL_STOCK_CHECK" || state.stage == "FOIL_REC") {
                        stockVariant = "Foil Quantity (in Kg)";
                        state.packingVariant = "FOIL";
                    } else if (state.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || state.stage == "BASE_FOIL_REC") {
                        stockVariant = "Base Foil Quantity (in Kg)";
                        state.packingVariant = "BASE_FOIL";
                    } else if (state.stage == "EXISTING_LABEL_STOCK_CHECK" || state.stage == "LABEL_REC") {
                        stockVariant = "Label Quantity";
                        state.packingVariant = "LABEL";
                    }

                    var z = document.createElement("h4");
                    z.textContent = stockVariant;
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "oldStockValue");
                    y.setAttribute("class", "flex-child green");
                    document.getElementById("packingMaterialStockId").appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "number");
                    z.setAttribute("step", "any");
                    z.addEventListener('change', function () {
                        state.quantity = this.value;
                    });
                    y.appendChild(z);
                } else if (state.stage == "PRODUCT_BATCH_ALLOTTED") {
                    var y = document.createElement("div");
                    y.setAttribute("id", "oldStockName");
                    y.setAttribute("class", "flex-child magenta");
                    document.getElementById("packingMaterialStockId").appendChild(y);

                    var z = document.createElement("h4");
                    z.textContent = "Product Batch No";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "oldStockValue");
                    y.setAttribute("class", "flex-child green");
                    document.getElementById("packingMaterialStockId").appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.batchNo = this.value;
                    });
                    y.appendChild(z);
                }

            };

            const onUpload = () => {

                if (isAdmin == true) {

                    // Create an object of formData
                    const formData = new FormData();

                    formData.append("orderId", state.orderId);
                    formData.append("stage", state.stage);
                    formData.append("primaryOperator", state.primaryOperator);
                    formData.append("helpers", state.helpers);
                    formData.append("machineNo", state.machineNo);
                    formData.append("shiftNo", state.shiftNo);
                    formData.append("reason", state.reason);
                    formData.append("yield", state.yield);
                    formData.append("updatedBy", state.updatedBy);

                    var button = document.getElementById("updateButton");
                    button.disabled = true;
                    axios.post("https://cascamailsender.azurewebsites.net/updateproductionprocess", formData)
                        .then((response) => {
                            if (response.data === "STAGE_UPDATED") {
                                alert("Stage Updated");
                                window.location.reload();
                            } else {
                                button.disabled = false;
                            }
                        });


                    if (state.stage == "EXISTING_OUTER_BOX_STOCK_CHECK" || state.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || state.stage == "EXISTING_FOIL_STOCK_CHECK" || state.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || state.stage == "EXISTING_LABEL_STOCK_CHECK"
                        || state.stage == "OUTER_BOX_REC" || state.stage == "INNER_BOX_REC" || state.stage == "FOIL_REC" || state.stage == "BASE_FOIL_REC" || state.stage == "LABEL_REC") {

                        const formData1 = new FormData();

                        formData1.append("orderId", state.orderId);
                        formData1.append("packingVariant", state.packingVariant);
                        formData1.append("packingType", state.packingType);
                        formData1.append("quantity", state.quantity);
                        formData1.append("updatedBy", state.updatedBy);

                        axios.post("https://cascamailsender.azurewebsites.net/addpackingmaterialstock", formData1)
                            .then((response) => {
                                if (response.data == "PACKING_MATERIAL_STOCK_SAVED") {
                                    //alert("Packing Material Stock Saved")
                                }
                            });
                    } else if (state.stage == "PRODUCT_BATCH_ALLOTTED") {
                        const formData1 = new FormData();

                        formData1.append("orderId", state.orderId);
                        formData1.append("batchNo", state.batchNo);

                        axios.post("https://cascamailsender.azurewebsites.net/allotproductbatch", formData1)
                            .then((response) => {
                                if (response.data == "PRODUCT_BATCH_ALLOTTED") {
                                    //alert("Packing Material Stock Saved")
                                }
                            });
                    }
                } else {
                    alert("You are not authorized to perform this operation");
                }
            };

            const Orders = [];

            fetch('https://cascamailsender.azurewebsites.net/getallproductionorders', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Orders.push({ label: object.productName + "_" + object.packing + "_MRP_" + object.mrp + "_" + object.id, value: object });
            })

            const Stages = [
                { label: "Design Approved By Party", value: "DESIGN_APPROVAL_BY_PARTY" },
                { label: "Address Proof Submitted By Party", value: "ADDRESS_PROOF_DOC_SUBMISSION" },
                { label: "Design Approved By Production Team", value: "DESIGN_APPROVAL_BY_CASCA" },
                { label: "Existing Outer Box Stock Check", value: "EXISTING_OUTER_BOX_STOCK_CHECK" },
                { label: "Existing Inner Box Stock Check", value: "EXISTING_INNER_BOX_STOCK_CHECK" },
                { label: "Existing Foil Stock Check", value: "EXISTING_FOIL_STOCK_CHECK" },
                { label: "Existing Base Foil Stock Check", value: "EXISTING_BASE_FOIL_STOCK_CHECK" },
                { label: "Existing Label Stock Check", value: "EXISTING_LABEL_STOCK_CHECK" },
                { label: "Outer Box Sent For Printing", value: "OUTER_BOX_SENT_FOR_PRINTING" },
                { label: "Inner Box Sent For Printing", value: "INNER_BOX_SENT_FOR_PRINTING" },
                { label: "Foil Sent For Printing", value: "FOIL_SENT_FOR_PRINTING" },
                { label: "Base Foil Sent For Printing", value: "BASE_FOIL_SENT_FOR_PRINTING" },
                { label: "Label Sent For Printing", value: "LABEL_SENT_FOR_PRINTING" },
                { label: "Outer Box Received", value: "OUTER_BOX_REC" },
                { label: "Inner Box Received", value: "INNER_BOX_REC" },
                { label: "Foil Received", value: "FOIL_REC" },
                { label: "Base Foil Received", value: "BASE_FOIL_REC" },
                { label: "Label Received", value: "LABEL_REC" },
                { label: "Allot Product Batch", value: "PRODUCT_BATCH_ALLOTTED" },
                { label: "Stripping Started", value: "STRIPPING_STARTED" },
                { label: "Stripping Completed", value: "STRIPPING_COMPLETED" },
                { label: "Liquid Labelling Started", value: "LIQUID_LABELLING_STARTED" },
                { label: "Liquid Labelling Completed", value: "LIQUID_LABELLING_COMPLETED" },
                { label: "Dry Syp Labelling Started", value: "DRY_SYP_LABELLING_STARTED" },
                { label: "Dry Syp Labelling Completed", value: "DRY_SYP_LABELLING_COMPLETED" },
                { label: "Final Packing Started", value: "FINAL_PACKING_STARTED" },
                { label: "Goods Packed and Ready To Dispatch", value: "READY_TO_DISPATCH" },
                { label: "Goods Dispatched", value: "GOODS_DISPATCHED" },
                { label: "Process Stopped", value: "PROCESS_STOPPED" }
            ]

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Update Product Stage</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Order Details</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={Orders} id="order" name="order">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Stage</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeStage.bind(this)} options={Stages} id="stage" name="stage">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container" id="packingMaterialStockId">

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Primary Operator</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.primaryOperator = event.target.value; }} type="text" id="primaryOperator" name="primaryOperator"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Helpers</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.helpers = event.target.value; }} type="text" id="helpers" name="helpers"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Machine No</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.machineNo = event.target.value; }} type="text" id="machineNo" name="machineNo"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Shift No</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.shiftNo = event.target.value; }} type="text" id="shiftNo" name="shiftNo"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Reason</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.reason = event.target.value; }} type="text" id="reason" name="reason"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Yield</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.yield = event.target.value; }} type="number" id="yield" name="yield"></input>
                        </div>

                    </div>

                    <button class="button" id="updateButton" onClick={onUpload}>UPDATE</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}