import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function ViewProductOrderDetails() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isPurchaseAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles == 'administrator' || jsonObject2.userRoles == 'purchaseAdministrator');

            const handleChange = event => {
                var productOrder = event.value;

                var inputProductName = document.getElementById("productName");
                var inputPacking = document.getElementById("packing");
                var inputMrp = document.getElementById("mrp");
                var inputQuantity = document.getElementById("quantity");
                var inputExpiryRequired = document.getElementById("expiryRequired");
                var inputRemarks = document.getElementById("remarks");
                var inputNonRefundablePDC = document.getElementById("nonRefundablePDC");
                var inputRefundablePDC = document.getElementById("refundablePDC");
                var inputRate = document.getElementById("rate");

                inputProductName.value = event.value.productName;
                inputPacking.value = event.value.packing;
                inputMrp.value = event.value.mrp;
                inputQuantity.value = event.value.quantity;
                inputExpiryRequired.value = event.value.expiryRequired;
                inputRemarks.value = event.value.remarks;
                inputNonRefundablePDC.value = event.value.nonRefundablePDC;
                inputRefundablePDC.value = event.value.refundablePDC;
                inputRate.value = event.value.rate;


                if (document.getElementById("categoryId") != null) {
                    document.getElementById("categoryId").remove();
                }

                var a = document.createElement("div");
                a.setAttribute("id", "categoryId");
                document.getElementById("categorySpecificConfId").appendChild(a);

                if (productOrder.category === "TABLET") {

                    // Strip Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "stripTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Strip Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.stripType;
                    y.appendChild(z);


                    // Base Foil Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "baseFoilTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Base Foil Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.baseFoilType;
                    y.appendChild(z);


                    // Tablet Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "tabletColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Tablet Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.tabletColour;
                    y.appendChild(z);


                } else if (productOrder.category === "CAPSULE") {

                    // Strip Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "stripTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Strip Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.stripType;
                    y.appendChild(z);


                    // Base Foil Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "baseFoilTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Base Foil Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.baseFoilType;
                    y.appendChild(z);


                    // Capsule Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "capsuleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Capsule Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.capsuleColour;
                    y.appendChild(z);

                } else if (productOrder.category === "LIQUID") {

                    // Bottle Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.bottleType;
                    y.appendChild(z);

                    // Bottle Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.bottleColour;
                    y.appendChild(z);

                    // Liquid Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.liquidColour;
                    y.appendChild(z);


                    // Liquid Flavour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidFlavourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Flavour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.liquidFlavour;
                    y.appendChild(z);


                    // Cap Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "capTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Cap Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.capType;
                    y.appendChild(z);

                    // Dose Cap Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "doseCapTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "doseCapTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Dose Cap Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "doseCapTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.doseCapType;
                    y.appendChild(z);

                } else {

                    // Bottle Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.disabled = true;
                    z.value = productOrder.bottleType;
                    y.appendChild(z);
                }
            };

            const Orders = [];

            fetch('https://cascamailsender.azurewebsites.net/getallproductionorders', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Orders.push({ label: object.productName + "_" + object.packing + "_MRP_" + object.mrp + "_" + object.id, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>View Product Order Details</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Order Details</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={Orders} id="order" name="order">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="productName" name="productName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>MRP</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="number" step="0.01" id="mrp" name="mrp"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="number" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Rate</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="number" step="0.01" id="rate" name="rate"></input>
                        </div>

                    </div>

                    <div id="categorySpecificConfId">

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Expiry Required</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="number" id="expiryRequired" name="expiryRequired"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Non Refundable PDC</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="number" id="nonRefundablePDC" name="nonRefundablePDC"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Refundable PDC</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="number" id="refundablePDC" name="refundablePDC"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Remarks</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="remarks" name="remarks"></input>
                        </div>

                    </div>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}