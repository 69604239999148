import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function MergeProductionBatches() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            const state = {
                composition: "",
                category: "",
                updatedBy: jsonObject2.name
            };

            const handleChangeBatchDetails = event => {
                state.composition = event.value.composition;
                state.category = event.value.category;

                if (document.getElementById("myTableBody") != null) {
                    document.getElementById("myTableBody").remove();
                }

                var y = document.createElement("tbody");
                y.setAttribute("id", "myTableBody");
                document.getElementById("myTable").appendChild(y);

                const formData = new FormData();
                formData.append("composition", state.composition)
                formData.append("category", state.category)
                axios.post("https://cascamailsender.azurewebsites.net/getproductionbatchestobemergedbycomposition", formData)
                    .then((response) => {
                        response.data.forEach((productionProcessBatch) => {

                            var y = document.createElement("tr");
                            y.setAttribute("id", productionProcessBatch.id);
                            document.getElementById("myTableBody").appendChild(y);


                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createElement("input");
                            t.setAttribute("type", "checkbox");
                            t.setAttribute("id", productionProcessBatch.id);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcessBatch.id);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcessBatch.orderIds);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcessBatch.totalQuantity);
                            z.appendChild(t);
                            y.appendChild(z);
                            
                            var remarks = "";
                            if (state.category === "TABLET") {
                                remarks = "Tablet Shape: " + productionProcessBatch.tabletShape + ", Tablet Colour: "
                                    + productionProcessBatch.tabletColour + ", Remarks: " + productionProcessBatch.remarks;
                            } else if (state.category === "CAPSULE") {
                                remarks = "Capsule Colour: " + productionProcessBatch.capsuleColour
                                    + ", Remarks: " + productionProcessBatch.remarks;
                            } else if (state.category === "LIQUID") {
                                remarks = "Liquid Colour: " + productionProcessBatch.liquidColour + ", Liquid Flavour: "
                                    + productionProcessBatch.liquidFlavour + ", Remarks: " + productionProcessBatch.remarks;
                            } else {
                                remarks = "Remarks: " + productionProcessBatch.remarks;
                            }
                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(remarks);
                            z.appendChild(t);
                            y.appendChild(z);

                        });
                    });
            };

            const modifyAction = event => {

                const batchIdList = [];

                var grid = document.getElementById("myTableBody");
                //Reference the CheckBoxes in Table.
                var checkBoxes = grid.getElementsByTagName("input");
                //Loop through the CheckBoxes.
                for (var i = 0; i < checkBoxes.length; i++) {
                    if (checkBoxes[i].checked) {
                        batchIdList.push(checkBoxes[i].id);         
                    }
                }

                const formData = new FormData();
                formData.append("batchIdList", batchIdList)
                formData.append("updatedBy", state.updatedBy);

                axios.post("https://cascamailsender.azurewebsites.net/mergeproductionbatches", formData)
                    .then((response) => {
                        if (response.data === "PRODUCTION_BATCHES_MERGED") {
                            alert("Production Batches Merged");
                            window.location.reload();
                        }
                    });
            }

            const compositionsCategoryCount = [];

            fetch('https://cascamailsender.azurewebsites.net/getoverallproductionbatchestobemerged', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                compositionsCategoryCount.push({ label: object.composition + ", " + object.category + ", " + object.count, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Merge Production Batches</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Batch Details</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeBatchDetails.bind(this)} options={compositionsCategoryCount} id="batchDetails" name="batchDetails">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Production Batches</h4>
                        </div>

                        <div class="flex-child green">
                            <table class="border_class" id="myTable">
                                <tr>
                                    <th class="border_class">Select</th>
                                    <th class="border_class">Batch Id</th>
                                    <th class="border_class">Order Ids</th>
                                    <th class="border_class">Production Quantity</th>
                                    <th class="border_class">Details</th>
                                </tr>
                            </table>
                        </div>

                    </div>

                    <button class="button" onClick={modifyAction}>MERGE</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}