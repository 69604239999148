import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function UpdateBatchStage() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isProductionAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles === 'administrator' || jsonObject2.userRoles === 'productionAdministrator');

            const state = {
                batchId: "",
                stage: "",
                primaryOperator: "",
                helpers: "",
                machineNo: "",
                shiftNo: "",
                reason: "",
                yield: "",
                updatedBy: jsonObject2.name
            };

            const handleChangeBatchDetails = event => {
                state.batchId = event.value.id;
            };

            const handleChangeStage = event => {
                state.stage = event.value;
            };

            const onUpload = () => {

                if (isProductionAdmin == true) {
                    // Create an object of formData
                    const formData = new FormData();

                    formData.append("batchId", state.batchId);
                    formData.append("stage", state.stage);
                    formData.append("primaryOperator", state.primaryOperator);
                    formData.append("helpers", state.helpers);
                    formData.append("machineNo", state.machineNo);
                    formData.append("shiftNo", state.shiftNo);
                    formData.append("reason", state.reason);
                    formData.append("yield", state.yield);
                    formData.append("updatedBy", state.updatedBy);

                    var button = document.getElementById("updateButton");
                    button.disabled = true;
                    axios.post("https://cascamailsender.azurewebsites.net/updateproductionprocessbatch", formData)
                        .then((response) => {
                            if (response.data === "BATCH_UPDATED") {
                                alert("Batch Stage Updated");
                                window.location.reload();
                            } else {
                                button.disabled = false;
                            }
                        });
                } else {
                    alert("You are not authorized to perform this operation");
                }
            };

            const productionBatches = [];

            fetch('https://cascamailsender.azurewebsites.net/getoverallstartedproductionbatches', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                productionBatches.push({ label: object.masterBatchNo + ", " + object.composition + ", " + object.category + ", " + object.totalQuantity, value: object });
            })

            const Stages = [
                { label: "Granulation Started", value: "GRANULATION_STARTED" },
                { label: "Granulation Completed", value: "GRANULATION_COMPLETED" },
                { label: "Capsules Filling Started", value: "CAPSULE_FILLING_STARTED" },
                { label: "Capsules Filling Completed", value: "CAPSULE_FILLING_COMPLETED" },
                { label: "Punching Started", value: "PUNCHING_STARTED" },
                { label: "Punching Completed", value: "PUNCHING_COMPLETED" },
                { label: "Punching Uncoated Tablet Started", value: "PUNCHING_UNCOATED_STARTED" },
                { label: "Punching Uncoated Tablet Completed", value: "PUNCHING_UNCOATED_COMPLETED" },
                { label: "Coating Started", value: "COATING_STARTED" },
                { label: "Coating Completed", value: "COATING_COMPLETED" },
                { label: "Liquid Manufacturing Started", value: "LIQUID_MANUFACTURING_STARTED" },
                { label: "Liquid Manufacturing Completed", value: "LIQUID_MANUFACTURING_COMPLETED" },
                { label: "Liquid Filling Started", value: "LIQUID_FILLING_STARTED" },
                { label: "Liquid Filling Completed", value: "LIQUID_FILLING_COMPLETED" },
                { label: "Dry Syp Mixing Started", value: "DRY_SYP_MIXING_STARTED" },
                { label: "Dry Syp Mixing Completed", value: "DRY_SYP_MIXING_COMPLETED" },
                { label: "Dry Syp Filling Started", value: "DRY_SYP_FILLING_STARTED" },
                { label: "Dry Syp Filling Completed", value: "DRY_SYP_FILLING_COMPLETED" },
                { label: "Process Stopped", value: "PROCESS_STOPPED" }
            ]

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Update Batch Stage</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Batch Details</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeBatchDetails.bind(this)} options={productionBatches} id="batchDetails" name="batchDetails">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Batch Stage</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeStage.bind(this)} options={Stages} id="stage" name="stage">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Primary Operator</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.primaryOperator = event.target.value; }} type="text" id="primaryOperator" name="primaryOperator"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Helpers</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.helpers = event.target.value; }} type="text" id="helpers" name="helpers"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Machine No</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.machineNo = event.target.value; }} type="text" id="machineNo" name="machineNo"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Shift No</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.shiftNo = event.target.value; }} type="text" id="shiftNo" name="shiftNo"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Reason</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.reason = event.target.value; }} type="text" id="reason" name="reason"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Yield</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.yield = event.target.value; }} type="number" id="yield" name="yield"></input>
                        </div>

                    </div>

                    <button class="button" id="updateButton" onClick={onUpload}>UPDATE</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}