import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function ModifyProductionProcess() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [post3, setPost3] = React.useState(null);
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles === 'administrator'
                || jsonObject2.userRoles === 'thirdPartyAdministrator');

            const state = {
                orderId: "",
                prevProductName: "",
                prevComposition: "",
                prevPacking: "",
                prevMrp: "",
                prevQuantity: "",
                prevBottleType: "",
                prevBottleColour: "",
                prevStripType: "",
                prevBaseFoilType: "",
                prevCapType: "",
                prevLiquidColour: "",
                prevLiquidFlavour: "",
                prevTabletColour: "",
                prevCapsuleColour: "",
                prevCategory: "",
                prevNonRefundablePDC: 0,
                prevRefundablePDC: 0,
                prevRemarks: "",
                prevExpiryRequired: -1,
                prevDoseCapType: "",
                prevRate: -1,
                prevQuantityForProduction: "",
                newProductName: "",
                newComposition: "",
                newPacking: "",
                newMrp: "",
                newQuantity: "",
                newBottleType: "",
                newBottleColour: "",
                newStripType: "",
                newBaseFoilType: "",
                newCapType: "",
                newLiquidColour: "",
                newLiquidFlavour: "",
                newTabletColour: "",
                newCapsuleColour: "",
                newCategory: "",
                newNonRefundablePDC: 0,
                newRefundablePDC: 0,
                newRemarks: "",
                newExpiryRequired: -1,
                newDoseCapType: "",
                newRate: -1,
                newQuantityForProduction: "",
                updatedBy: jsonObject2.name
            };

            const handleChangeComposition = event => {
                state.newComposition = event.value.composition;
            };

            const handleChangeCategory = event => {
                state.newCategory = event.value;

                if (document.getElementById("categoryId") != null) {
                    document.getElementById("categoryId").remove();
                }

                var a = document.createElement("div");
                a.setAttribute("id", "categoryId");
                document.getElementById("categorySpecificConfId").appendChild(a);

                if (state.newCategory === "TABLET") {

                    // Strip Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "stripTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Strip Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newStripType = this.value;
                    });
                    y.appendChild(z);


                    // Base Foil Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "baseFoilTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Base Foil Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newBaseFoilType = this.value;
                    });
                    y.appendChild(z);


                    // Tablet Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "tabletColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Tablet Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newTabletColour = this.value;
                    });
                    y.appendChild(z);

                } else if (state.newCategory === "CAPSULE") {

                    // Strip Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "stripTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Strip Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newStripType = this.value;
                    });
                    y.appendChild(z);


                    // Base Foil Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "baseFoilTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Base Foil Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newBaseFoilType = this.value;
                    });
                    y.appendChild(z);


                    // Capsule Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "capsuleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Capsule Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newCapsuleColour = this.value;
                    });
                    y.appendChild(z);

                } else if (state.newCategory === "LIQUID") {

                    // Bottle Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newBottleType = this.value;
                    });
                    y.appendChild(z);

                    // Bottle Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newBottleColour = this.value;
                    });
                    y.appendChild(z);

                    // Liquid Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newLiquidColour = this.value;
                    });
                    y.appendChild(z);


                    // Liquid Flavour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidFlavourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Flavour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newLiquidFlavour = this.value;
                    });
                    y.appendChild(z);


                    // Cap Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "capTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Cap Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newCapType = this.value;
                    });
                    y.appendChild(z);

                    // Dose Cap Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "doseCapTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "doseCapTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Dose Cap Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "doseCapTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newDoseCapType = this.value;
                    });
                    y.appendChild(z);

                } else {

                    // Bottle Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.addEventListener('change', function () {
                        state.newBottleType = this.value;
                    });
                    y.appendChild(z);
                }
            };

            const handleChange = event => {
                state.orderId = event.value.id;

                var inputProductName = document.getElementById("productName");
                var inputComposition = document.getElementById("composition");
                var inputPacking = document.getElementById("packing");
                var inputMrp = document.getElementById("mrp");
                var inputQuantity = document.getElementById("quantity");
                var inputExpiryRequired = document.getElementById("expiryRequired");
                var inputRemarks = document.getElementById("remarks");
                var inputCategory = document.getElementById("category");
                var inputNonRefundablePDC = document.getElementById("nonRefundablePDC");
                var inputRefundablePDC = document.getElementById("refundablePDC");
                var inputRate = document.getElementById("rate");
                var inputQuantityForProduction = document.getElementById("quantityForProduction");

                inputProductName.value = event.value.productName;
                inputComposition.value = event.value.composition;
                inputPacking.value = event.value.packing;
                inputMrp.value = event.value.mrp;
                inputQuantity.value = event.value.quantity;
                inputExpiryRequired.value = event.value.expiryRequired;
                inputRemarks.value = event.value.remarks;
                inputCategory.value = event.value.category;
                inputNonRefundablePDC.value = event.value.nonRefundablePDC;
                inputRefundablePDC.value = event.value.refundablePDC;
                inputRate.value = event.value.rate;
                inputQuantityForProduction.value = event.value.quantityForProduction;

                state.prevProductName = event.value.productName;
                state.prevComposition = event.value.composition;
                state.prevPacking = event.value.packing;
                state.prevMrp = event.value.mrp;
                state.prevQuantity = event.value.quantity;
                state.prevBottleType = event.value.bottleType;
                state.prevBottleColour = event.value.bottleColour;
                state.prevStripType = event.value.stripType;
                state.prevBaseFoilType = event.value.baseFoilType;
                state.prevCapType = event.value.capType;
                state.prevLiquidColour = event.value.liquidColour;
                state.prevLiquidFlavour = event.value.liquidFlavour;
                state.prevTabletColour = event.value.tabletColour;
                state.prevExpiryRequired = event.value.expiryRequired;
                state.prevRemarks = event.value.remarks;
                state.prevCapsuleColour = event.value.capsuleColour;
                state.prevCategory = event.value.category;
                state.prevNonRefundablePDC = event.value.nonRefundablePdc;
                state.prevRefundablePDC = event.value.refundablePdc;
                state.prevDoseCapType = event.value.doseCapType;
                state.prevRate = event.value.rate;
                state.prevQuantityForProduction = event.value.quantityForProduction;

                state.newProductName = event.value.productName;
                state.newComposition = event.value.composition;
                state.newPacking = event.value.packing;
                state.newMrp = event.value.mrp;
                state.newQuantity = event.value.quantity;
                state.newBottleType = event.value.bottleType;
                state.newBottleColour = event.value.bottleColour;
                state.newStripType = event.value.stripType;
                state.newBaseFoilType = event.value.baseFoilType;
                state.newCapType = event.value.capType;
                state.newLiquidColour = event.value.liquidColour;
                state.newLiquidFlavour = event.value.liquidFlavour;
                state.newTabletColour = event.value.tabletColour;
                state.newExpiryRequired = event.value.expiryRequired;
                state.newRemarks = event.value.remarks;
                state.newCapsuleColour = event.value.capsuleColour;
                state.newCategory = event.value.category;
                state.newNonRefundablePDC = event.value.nonRefundablePdc;
                state.newRefundablePDC = event.value.refundablePdc;
                state.newDoseCapType = event.value.doseCapType;
                state.newRate = event.value.rate;
                state.newQuantityForProduction = event.value.quantityForProduction;

                if (document.getElementById("categoryId") != null) {
                    document.getElementById("categoryId").remove();
                }

                var a = document.createElement("div");
                a.setAttribute("id", "categoryId");
                document.getElementById("categorySpecificConfId").appendChild(a);

                if (state.prevCategory === "TABLET") {

                    // Strip Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "stripTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Strip Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.stripType;
                    z.addEventListener('change', function () {
                        state.newStripType = this.value;
                    });
                    y.appendChild(z);


                    // Base Foil Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "baseFoilTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Base Foil Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.baseFoilType;
                    z.addEventListener('change', function () {
                        state.newBaseFoilType = this.value;
                    });
                    y.appendChild(z);


                    // Tablet Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "tabletColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Tablet Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.tabletColour;
                    z.addEventListener('change', function () {
                        state.newTabletColour = this.value;
                    });
                    y.appendChild(z);

                } else if (state.prevCategory === "CAPSULE") {

                    // Strip Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "stripTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Strip Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "stripTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.stripType;
                    z.addEventListener('change', function () {
                        state.newStripType = this.value;
                    });
                    y.appendChild(z);


                    // Base Foil Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "baseFoilTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Base Foil Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "baseFoilTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.baseFoilType;
                    z.addEventListener('change', function () {
                        state.newBaseFoilType = this.value;
                    });
                    y.appendChild(z);


                    // Capsule Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "capsuleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Capsule Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.capsuleColour;
                    z.addEventListener('change', function () {
                        state.newCapsuleColour = this.value;
                    });
                    y.appendChild(z);

                } else if (state.prevCategory === "LIQUID") {

                    // Bottle Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.bottleType;
                    z.addEventListener('change', function () {
                        state.newBottleType = this.value;
                    });
                    y.appendChild(z);

                    // Bottle Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.bottleColour;
                    z.addEventListener('change', function () {
                        state.newBottleColour = this.value;
                    });
                    y.appendChild(z);

                    // Liquid Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.liquidColour;
                    z.addEventListener('change', function () {
                        state.newLiquidColour = this.value;
                    });
                    y.appendChild(z);


                    // Liquid Flavour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidFlavourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Flavour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.liquidFlavour;
                    z.addEventListener('change', function () {
                        state.newLiquidFlavour = this.value;
                    });
                    y.appendChild(z);


                    // Cap Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "capTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Cap Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.capType;
                    z.addEventListener('change', function () {
                        state.newCapType = this.value;
                    });
                    y.appendChild(z);

                    // Dose Cap Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "doseCapTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "doseCapTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Dose Cap Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "doseCapTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.doseCapType;
                    z.addEventListener('change', function () {
                        state.newDoseCapType = this.value;
                    });
                    y.appendChild(z);

                } else {

                    // Bottle Type
                    var x = document.createElement("div");
                    x.setAttribute("id", "bottleTypeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Bottle Type";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "bottleTypeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("input");
                    z.setAttribute("type", "text");
                    z.value = event.value.bottleType;
                    z.addEventListener('change', function () {
                        state.newBottleType = this.value;
                    });
                    y.appendChild(z);
                }

            };

            const modifyAction = event => {

                if (isAdmin == true) {

                    if (state.prevProductName == state.newProductName
                        && state.prevComposition == state.newComposition
                        && state.prevPacking == state.newPacking
                        && state.prevMrp == state.newMrp
                        && state.prevQuantity == state.newQuantity
                        && state.prevBottleType == state.newBottleType
                        && state.prevBottleColour == state.newBottleColour
                        && state.prevStripType == state.newStripType
                        && state.prevBaseFoilType == state.newBaseFoilType
                        && state.prevCapType == state.newCapType
                        && state.prevLiquidColour == state.newLiquidColour
                        && state.prevLiquidFlavour == state.newLiquidFlavour
                        && state.prevTabletColour == state.newTabletColour
                        && state.prevExpiryRequired == state.newExpiryRequired
                        && state.prevRemarks == state.newRemarks
                        && state.prevCapsuleColour == state.newCapsuleColour
                        && state.prevCategory == state.newCategory
                        && state.prevNonRefundablePDC == state.newNonRefundablePDC
                        && state.prevRefundablePDC == state.newRefundablePDC
                        && state.prevDoseCapType == state.newDoseCapType
                        && state.prevRate == state.newRate
                        && state.prevQuantityForProduction == state.newQuantityForProduction) {
                        alert("All the fields are same!");
                        return;
                    }

                    if (state.newCategory == "TABLET") {
                        state.newBottleType = "";
                        state.newBottleColour = "";
                        state.newLiquidColour = "";
                        state.newLiquidFlavour = "";
                        state.newCapType = "";
                        state.newDoseCapType = "";
                        state.newCapsuleColour = "";
                    } else if (state.newCategory == "CAPSULE") {
                        state.newBottleType = "";
                        state.newBottleColour = "";
                        state.newLiquidColour = "";
                        state.newLiquidFlavour = "";
                        state.newCapType = "";
                        state.newDoseCapType = "";
                        state.newTabletColour = "";
                    } else if (state.newCategory == "LIQUID") {
                        state.newBaseFoilType = "";
                        state.newStripType = "";
                        state.newTabletColour = "";
                        state.newCapsuleColour = "";
                    } else {
                        state.newBaseFoilType = "";
                        state.newStripType = "";
                        state.newTabletColour = "";
                        state.newCapsuleColour = "";
                        state.newBottleColour = "";
                        state.newLiquidColour = "";
                        state.newLiquidFlavour = "";
                        state.newCapType = "";
                        state.newDoseCapType = "";
                    }

                    const formData = new FormData();
                    formData.append("orderId", state.orderId)
                    formData.append("prevProductName", state.prevProductName)
                    formData.append("prevComposition", state.prevComposition)
                    formData.append("prevPacking", state.prevPacking)
                    formData.append("prevMrp", state.prevMrp)
                    formData.append("prevQuantity", state.prevQuantity)
                    formData.append("prevBottleType", state.prevBottleType)
                    formData.append("prevBottleColour", state.prevBottleColour)
                    formData.append("prevStripType", state.prevStripType)
                    formData.append("prevBaseFoilType", state.prevBaseFoilType)
                    formData.append("prevCapType", state.prevCapType)
                    formData.append("prevLiquidColour", state.prevLiquidColour)
                    formData.append("prevLiquidFlavour", state.prevLiquidFlavour)
                    formData.append("prevTabletColour", state.prevTabletColour)
                    formData.append("prevExpiryRequired", state.prevExpiryRequired)
                    formData.append("prevRemarks", state.prevRemarks)
                    formData.append("prevCapsuleColour", state.prevCapsuleColour)
                    formData.append("prevCategory", state.prevCategory)
                    formData.append("prevNonRefundablePDC", state.prevNonRefundablePDC)
                    formData.append("prevRefundablePDC", state.prevRefundablePDC)
                    formData.append("prevDoseCapType", state.prevDoseCapType)
                    formData.append("prevRate", state.prevRate)
                    formData.append("prevQuantityForProduction", state.prevQuantityForProduction)
                    formData.append("newProductName", state.newProductName)
                    formData.append("newComposition", state.newComposition)
                    formData.append("newPacking", state.newPacking)
                    formData.append("newMrp", state.newMrp)
                    formData.append("newQuantity", state.newQuantity)
                    formData.append("newBottleType", state.newBottleType)
                    formData.append("newBottleColour", state.newBottleColour)
                    formData.append("newStripType", state.newStripType)
                    formData.append("newBaseFoilType", state.newBaseFoilType)
                    formData.append("newCapType", state.newCapType)
                    formData.append("newLiquidColour", state.newLiquidColour)
                    formData.append("newLiquidFlavour", state.newLiquidFlavour)
                    formData.append("newTabletColour", state.newTabletColour)
                    formData.append("newExpiryRequired", state.newExpiryRequired)
                    formData.append("newRemarks", state.newRemarks)
                    formData.append("newCapsuleColour", state.newCapsuleColour)
                    formData.append("newCategory", state.newCategory)
                    formData.append("newNonRefundablePDC", state.newNonRefundablePDC)
                    formData.append("newRefundablePDC", state.newRefundablePDC)
                    formData.append("newDoseCapType", state.newDoseCapType)
                    formData.append("newRate", state.newRate)
                    formData.append("newQuantityForProduction", state.newQuantityForProduction)
                    formData.append("updatedBy", state.updatedBy);

                    axios.post("https://cascamailsender.azurewebsites.net/modifyproductionorder", formData)
                        .then((response) => {
                            if (response.data === "ORDER_MODIFIED") {
                                alert("Order Modified");
                                window.location.reload();
                            }
                        });
                } else {
                    alert("You are not authorized to perform this operation");
                }
            }

            const ProductCategories = [
                { label: "Tablets", value: "TABLET" },
                { label: "Capsules", value: "CAPSULE" },
                { label: "Oral Liquids", value: "LIQUID" },
                { label: "Dry Syrup", value: "DRY_SYRUP" }
            ]

            const Orders = [];

            fetch('https://cascamailsender.azurewebsites.net/getallproductionorders', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Orders.push({ label: object.productName + "_" + object.packing + "_MRP_" + object.mrp + "_" + object.id, value: object });
            })

            const Compositions = [];

            fetch('https://cascamailsender.azurewebsites.net/getallcompositions', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost3(data));

            if (!post3) return null;

            var postModified3 = post3.replace(/'/g, '"');
            let jsonData3 = JSON.parse(JSON.stringify(postModified3));
            let jsonObject3 = JSON.parse(jsonData3);
            jsonObject3.forEach(object => {
                Compositions.push({ label: object.composition + ", " + object.category, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Modify Product Order</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Order Details</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={Orders} id="order" name="order">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newProductName = event.target.value; }} type="text" id="productName" name="productName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeComposition.bind(this)} options={Compositions} id="composition" name="composition">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newPacking = event.target.value; }} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>MRP</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newMrp = event.target.value; }} type="number" step="0.01" id="mrp" name="mrp"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newQuantity = event.target.value; }} type="number" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Rate</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newRate = event.target.value; }} type="number" step="0.01" id="rate" name="rate"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Category</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeCategory.bind(this)} options={ProductCategories} id="category" name="category">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="categorySpecificConfId">

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Expiry Required</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newExpiryRequired = event.target.value; }} type="number" id="expiryRequired" name="expiryRequired"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Non Refundable PDC</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newNonRefundablePDC = event.target.value; }} type="number" id="nonRefundablePDC" name="nonRefundablePDC"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Refundable PDC</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newRefundablePDC = event.target.value; }} type="number" id="refundablePDC" name="refundablePDC"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Production Quantity (no of tablets/capsules or litres in liquid)</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newQuantityForProduction = event.target.value; }} type="number" id="quantityForProduction" name="quantityForProduction"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Remarks</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.newRemarks = event.target.value; }} type="text" id="remarks" name="remarks"></input>
                        </div>

                    </div>

                    <button class="button" onClick={modifyAction}>MODIFY</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}