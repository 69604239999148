import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function AllotProductionBatch() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {

            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            var isProductionAdmin = jsonObject2.userRoles != null && (jsonObject2.userRoles === 'administrator' || jsonObject2.userRoles === 'productionAdministrator');

            const state = {
                masterBatchNo: "",
                batchId: "",
                updatedBy: jsonObject2.name
            };

            const handleChangeBatchDetails = event => {

                var inputComposition = document.getElementById("composition");
                var inputCategory = document.getElementById("category");
                var inputTotalQuantity = document.getElementById("totalQuantity");
                var inputRemarks = document.getElementById("remarks");          

                inputComposition.value = event.value.composition;
                inputRemarks.value = event.value.remarks;
                inputCategory.value = event.value.category;
                inputTotalQuantity.value = event.value.totalQuantity;

                if (document.getElementById("categoryId") != null) {
                    document.getElementById("categoryId").remove();
                }

                var a = document.createElement("div");
                a.setAttribute("id", "categoryId");
                document.getElementById("categorySpecificConfId").appendChild(a);

                if (event.value.category === "TABLET") {

                    // Tablet Shape
                    var x = document.createElement("div");
                    x.setAttribute("id", "tabletShapeId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletShapeName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Tablet Shape";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletShapeValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("h5");
                    z.textContent = event.value.tabletShape;
                    y.appendChild(z);

                    // Tablet Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "tabletColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Tablet Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "tabletColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("h5");
                    z.textContent = event.value.tabletColour;
                    y.appendChild(z);

                } else if (event.value.category === "CAPSULE") {

                    // Capsule Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "capsuleColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Capsule Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "capsuleColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("h5");
                    z.textContent = event.value.capsuleColour;
                    y.appendChild(z);

                } else if (event.value.category === "LIQUID") {

                    // Liquid Colour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidColourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Colour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidColourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("h5");
                    z.textContent = event.value.liquidColour;
                    y.appendChild(z);


                    // Liquid Flavour
                    var x = document.createElement("div");
                    x.setAttribute("id", "liquidFlavourId");
                    x.setAttribute("class", "flex-container");
                    a.appendChild(x);


                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourName");
                    y.setAttribute("class", "flex-child magenta");
                    x.appendChild(y);


                    var z = document.createElement("h4");
                    z.textContent = "Liquid Flavour";
                    y.appendChild(z);

                    var y = document.createElement("div");
                    y.setAttribute("id", "liquidFlavourValue");
                    y.setAttribute("class", "flex-child green");
                    x.appendChild(y);

                    var z = document.createElement("h5");
                    z.textContent = event.value.liquidFlavour;
                    y.appendChild(z);

                }   

                if (document.getElementById("myTableBody") != null) {
                    document.getElementById("myTableBody").remove();
                }

                var y = document.createElement("tbody");
                y.setAttribute("id", "myTableBody");
                document.getElementById("myTable").appendChild(y);


                state.batchId = event.value.id;
                var url = "https://cascamailsender.azurewebsites.net/getassociatedproducts?batchId=" + state.batchId;
                axios.get(url)
                    .then((response) => {
                        response.data.forEach((productionProcess) => {

                            var y = document.createElement("tr");
                            y.setAttribute("id", productionProcess.id);
                            document.getElementById("myTableBody").appendChild(y);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.id);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.productName);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.packing);
                            z.appendChild(t);
                            y.appendChild(z);

                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(productionProcess.quantity);
                            z.appendChild(t);
                            y.appendChild(z);
                            
                            var remarks = "";
                            if (productionProcess.category === "TABLET") {
                                remarks = "Strip Type: " + productionProcess.stripType + ", Base Foil Type: "
                                    + productionProcess.baseFoilType + ", Remarks: " + productionProcess.remarks;
                            } else if (productionProcess.category === "CAPSULE") {
                                remarks = "Strip Type: " + productionProcess.stripType + ", Base Foil Type: "
                                    + productionProcess.baseFoilType + ", Remarks: " + productionProcess.remarks;
                            } else if (productionProcess.category === "LIQUID") {
                                remarks = "Bottle Type: " + productionProcess.bottleType + ", Bottle Colour: "
                                    + productionProcess.bottleColour + ", Cap Type: " + productionProcess.capType
                                    + ", Dose Cap Type: " + productionProcess.doseCapType + ", Remarks: "
                                    + productionProcess.remarks;
                            } else {
                                remarks = "Bottle Type: " + productionProcess.bottleType + ", Remarks: "
                                    + productionProcess.remarks;
                            }
                            var z = document.createElement("td");
                            z.setAttribute("class", "border_class");
                            var t = document.createTextNode(remarks);
                            z.appendChild(t);
                            y.appendChild(z);

                        });
                    });
            };

            const modifyAction = event => {

                if (isProductionAdmin == true) {

                    const formData = new FormData();
                    formData.append("batchId", state.batchId)
                    formData.append("masterBatchNo", state.masterBatchNo)
                    formData.append("updatedBy", state.updatedBy);

                    var button = document.getElementById("allotButton");
                    button.disabled = true;
                    axios.post("https://cascamailsender.azurewebsites.net/allotproductionbatch", formData)
                        .then((response) => {
                            if (response.data === "PRODUCTION_BATCH_ALLOTTED") {
                                alert("Production Batch Allotted");
                                window.location.reload();
                            } else {
                                button.disabled = false;
                            }
                        });
                } else {
                    alert("You are not authorized to perform this operation");
                }
            }

            const productionBatches = [];

            fetch('https://cascamailsender.azurewebsites.net/getoverallpendingproductionbatches', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                productionBatches.push({ label: object.composition + ", " + object.category + ", " + object.totalQuantity, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Allot Production Batch</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Updated By</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} value={jsonObject2.name} type="text" id="updatedBy" name="updatedBy"></input>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Batch Details</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChangeBatchDetails.bind(this)} options={productionBatches} id="batchDetails" name="batchDetails">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Category</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="category" name="category"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Total Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="totalQuantity" name="totalQuantity"></input>
                        </div>

                    </div>

                    <div id="categorySpecificConfId">

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Remarks</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="remarks" name="remarks"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Orders</h4>
                        </div>

                        <div class="flex-child green">
                            <table class="border_class" id="myTable">
                                <tr>
                                    <td class="border_class">Order Id</td>
                                    <td class="border_class">Product Name</td>
                                    <td class="border_class">Packing</td>
                                    <td class="border_class">Quantity</td>
                                    <td class="border_class">Remarks</td>
                                </tr>
                            </table>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Master Batch No</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.masterBatchNo = event.target.value; }} type="text" id="masterBatchNo" name="masterBatchNo"></input>
                        </div>

                    </div> 

                    <button class="button" id="allotButton" onClick={modifyAction}>ALLOT</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}